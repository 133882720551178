import * as React from "react"
import { FC } from "react"
import styled from "styled-components"
import ImageType from "../../model/ImageType"
import GImage from "../../../gatsby/GImage"
import { desktop, largeDesktop, tablet } from "../../constants/screenSizes"
import { darkBlue } from "../../constants/colors"
import { UrlImage } from "components"

const Layout = styled.div`
  height: 400px;
  position: relative;
  width: 100%;
  max-width: 1300px;
  margin: auto;
`

const PlainBackground = styled.div`
  width: 100%;
  height: 400px;
  background-color: #fcf7eb;
`

const OuterContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: auto;
`

const InnerContainer = styled.div`
  height: 100%;
  max-width: 1200px;
  margin: auto;
  position: relative;
  @media (min-width: ${desktop}px) and (max-width: ${largeDesktop}px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`

const BadgeContainer = styled.div`
  width: 70%;
  height: 200px;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: rgba(255, 255, 255, 0.92);
  @media (max-width: ${desktop}px) {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
`

const LogoContainer = styled.div`
  position: absolute;
  bottom: 130px;
  left: calc(50% - 75px);
  background-color: white;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15), 0 0 1px -2px rgba(0, 0, 0, 0.15),
    0 2px 12px 0 rgba(0, 0, 0, 0.15);
  padding: 10px;
  @media (max-width: ${desktop}px) {
    bottom: 130px;
  }
`

const TitleContainer = styled.div<{ isLogoAbsent: boolean }>`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  ${({ isLogoAbsent = false }) => (isLogoAbsent ? "height: 100%;" : "height: 135px;")}
`

const Title = styled.h1`
  font-size: 2.2em;
  max-width: 600px;
  @media (max-width: ${tablet}px) {
    font-size: 1.8em;
  }
`
const StyledUrlImage = styled(UrlImage)`
  width: 100%;
  height: 400px;
`

interface Props {
  bannerImage: ImageType
  logoImage?: ImageType
  title: string
}

const BannerLayout: FC<Props> = ({ bannerImage, logoImage, title }) => (
  <Layout>
    {bannerImage ? (
      <StyledUrlImage image={bannerImage} objectFit="cover" sizes={"1200px"} />
    ) : (
      <PlainBackground />
    )}
    <OuterContainer>
      <InnerContainer>
        <BadgeContainer>
          {logoImage && (
            <LogoContainer>
              <GImage image={logoImage} style={{ width: 120, height: 120, objectFit: "contain" }} />
            </LogoContainer>
          )}
          <TitleContainer isLogoAbsent={!logoImage}>
            <Title>{title}</Title>
          </TitleContainer>
        </BadgeContainer>
      </InnerContainer>
    </OuterContainer>
  </Layout>
)

export default BannerLayout
